import React, { Component } from 'react';
import { Route } from 'react-router';
import { Home } from './components/Home';
import Form from './components/Form/Form';
import Thanks from './components/Thanks/Thanks';
import { Layout } from './components/Layout';

export default class App extends Component {
  render () {
    return (
      <Layout>
        <Route exact path="/" component={Home} />
        <Route exact path="/ny-indberetning" component={Form} />
        <Route exact path="/tak" component={Thanks} />
      </Layout>
    );
  }
}
