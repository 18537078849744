import { IComplaintDTO } from "../models/IComplaintDTO";

export interface IComplaintService
{
    CreateComplaint(model: IComplaintDTO) : Promise<boolean>;
}

export class ComplaintServiceProvider implements IComplaintService
{
    public async CreateComplaint(model: IComplaintDTO) : Promise<boolean>
    {   
        if(!model.orderNo)
        {
            return false;
        }

        const requestOptions = {
            method: 'POST',            
            body: JSON.stringify(model),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch('api/Complaint', requestOptions);
        if(!response.ok)
        {
            alert('Der er sket en fejl. Prøv igen senere.');
            return false;
        } else
        {
            return true;
        }        
    }
}