import * as React from 'react';
import { Spinner, SpinnerSize } from "@fluentui/react";

export interface ISpinnerButtonProps {
    ShowSpinner: boolean;
}

export const SpinnerButton: React.FunctionComponent<ISpinnerButtonProps> = (props: React.PropsWithChildren<ISpinnerButtonProps>) => {
    return (
        <div style={{ display: "inline-flex" }}>
            {props.ShowSpinner && <Spinner size={SpinnerSize.xSmall} style={{ marginRight: 5 }} />}
            {props.children}
        </div>
    );
};

