import React, { Component } from 'react';

export class Home extends Component {
    render () {
      return (
            <div>
              <h1>Sådan gør du</h1>
              <p>For at lette adminstrationen ved reklamationer kan du som forhandler indberette online. For at indberette en reklamation skal du have følgende informationer i hænde:</p>
              <ul>
                <li>Ordrenummer (f.eks. 0010177022)</li>
                <li>Beskrivelse af reklamationen / fejlen</li>
                <li>Evt. billeddokumentation</li>
              </ul>
              <p>Kom i gang <a href="/ny-indberetning">her</a> eller via linket 'Start ny indberetning' øverst i højre hjørne.</p>
            </div>
      );
    }
  }