import { DatePicker, DayOfWeek, Dropdown, IconButton, IDropdownOption, Label, MaskedTextField, PrimaryButton, TextField, Toggle } from '@fluentui/react';
import React, { useEffect, useCallback } from 'react';
import styles from './Form.module.scss';
import {useDropzone} from 'react-dropzone';
import { ComplaintServiceProvider } from '../../services/ComplaintService';
import { IAdditionalInfoFromWebsite, IComplaintDTO, IFile } from '../../models/IComplaintDTO';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import ReCAPTCHA from 'react-google-recaptcha';
import { SpinnerButton } from './SpinnerButton';

initializeIcons();

const yesNoOptions = [{ key: 'yes', text: 'Ja' },{ key: 'no', text: 'Nej' },{ key: 'na', text: 'Ved ikke' }] as IDropdownOption[];

const Form = (props: IFormProps) => { 
  const [model, updateModel] = React.useState<IFormModel>({files: []});
  const [isInspected, setIsInspected] = React.useState<boolean | null>(null);
  const [isInstalled, setIsInstalled] = React.useState<boolean | null>(null);

  const [canSubmit, setCanSubmit] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);  

  const [reCaptchaToken, setReCaptchaToken] = React.useState<string>();

  useEffect(() => {
    setCanSubmit(model.orderNo != undefined && model.description != undefined && model.contactName != undefined && model.contactEmail != undefined && reCaptchaToken != undefined);
  }, [model, reCaptchaToken]);
  
  const onDrop = useCallback(async (acceptedFiles : any) => {
    const filePromises = acceptedFiles.map((file : any) : Promise<IFile> => {
      // Return a promise per file
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {
          try {
            const newFile = { name: (file as File).name, content: reader.result } as IFile;
            // Resolve the promise with the response value
            resolve(newFile);
          } catch (err) {
            reject(err);
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    });
  
    // Wait for all promises to be resolved
    const fileInfos : IFile[] = await Promise.all(filePromises);
    
    updateModel(prevState => ({...prevState, files: prevState.files?.concat(fileInfos)}));
    
  }, [])

  const removeFile = (file: IFile) => {
    
    const newFiles = model.files as IFile[];
    if(newFiles)
    {
      newFiles.splice(newFiles.indexOf(newFiles.filter(d => d.name == file.name)[0]), 1);
      updateModel(prevState => ({...prevState, files: newFiles}));   
    }
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const files = model.files?.map(file => (
    <li key={file.name}>
      {file.name}
      <IconButton iconProps={{iconName: "Delete"}} onClick={() => { removeFile(file) }}></IconButton>
    </li>
  ));

  const submit = async () => {
    setIsSubmitting(true);

    let payload = {orderNo: model.orderNo, m2: model.m2, files: model.files, reCaptchaToken: reCaptchaToken} as IComplaintDTO;
    payload.additionalInfo = {
      contactCompanyName: model.company,
      contactName: model.contactName,
      contactEmail: model.contactEmail,
      contactMobilePhone: model.contactMobilePhone,
      quality: model.quality,
      design: model.design,
      customerName: model.customerName,
      customerAddress: model.customerAddress,
      customerZipCode: model.customerZipCode,
      customerCity: model.customerCity,
      hasTheProductOrInstallationBeenPhysicallyInspected: model.hasTheProductOrInstallationBeenPhysicallyInspected,
      dateOfInspection: model.dateOfInspection,
      inspectedBy: model.inspectedBy,
      roomType: model.roomType,
      subfloor: model.subfloor,
      isFloorLevelnessMeasured: model.isFloorLevelnessMeasured,
      floorHeating: model.floorHeating,
      description: model.description
    } as IAdditionalInfoFromWebsite;

    let result = await new ComplaintServiceProvider().CreateComplaint(payload);

    if(result == true)
    {
      window.location.href = "/tak";
    } else
    {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h1>Ny indberetning</h1>
      <div className={styles.section}>
        <div className={styles.heading}>Reklamation udfyldt af</div>
        <div className={styles.content}>
          <TextField label="Forhandler" required={true} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, company: newValue})); }} />
          <TextField label="Navn" required={true} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, contactName: newValue})); }} />
          <TextField label="E-mail" required={true} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, contactEmail: newValue})); }} />
          <TextField label="Mobiltelefon" required={false} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, contactMobilePhone: newValue})); }} />
        </div>
      </div>
      
      <div className={styles.section}>
        <div className={styles.heading}>Ordre information</div>
        <div className={styles.content}>          
          <MaskedTextField mask="0010999999" label="Ege Carpets ordrenummer" required={true} placeholder="Angiv ordrenummer, f.eks. 0010177022" onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, orderNo: newValue})); }} /> 
          <TextField label="Kvalitet" required={false} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, quality: newValue})); }} /> 
          <TextField label="Design" required={false} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, design: newValue})); }} />
          <Toggle label="Er tæppet monteret?" onChange={(ev, checked) => { setIsInstalled(checked as boolean); }} />
        </div>
      </div>            
      { isInstalled && 
        <div className={styles.section}>
          <div className={styles.heading}>Monteringsadresse</div>
          <div className={styles.content}>
            <TextField label="Kundenavn" required={false} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, customerName: newValue})); }} />
            <TextField label="Adresse" required={false} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, customerAddress: newValue})); }} />
            <TextField label="Postnummer" required={false} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, customerZipCode: newValue})); }} />
            <TextField label="By" required={false} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, customerCity: newValue})); }} />
          </div>
        </div>
      }
      
      <div className={styles.section}>
          <div className={styles.heading}>Dokumentation</div>
          <div className={styles.content}>
            <Dropdown label="Er produktet/installationen besigtiget?" options={yesNoOptions} defaultSelectedKey={"na"} onChange={(ev, option) => { setIsInspected(option?.key == "yes"); updateModel(prevState => ({...prevState, hasTheProductOrInstallationBeenPhysicallyInspected: option?.key + ""}));} } />
            {isInspected && 
            <>
              <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                placeholder="Vælg dato for besigtelse"                
                label={"Dato for besigtelse"}
                onSelectDate={(date: Date | null | undefined) => { if(date) updateModel(prevState => ({...prevState, dateOfInspection: date})); }}
              />
              <TextField label="Besigtiget af" required={false} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, inspectedBy: newValue})); }} />
            </>}
            <TextField label="Rum type" placeholder='Eksempler: Stue, værelse, kontor etc.' required={false} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, roomType: newValue})); }} />
            <TextField label="Antal kvadratmeter" suffix='m²' required={false} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, m2: newValue})); }} />
            <TextField label="Hvordan er undergulvet?" required={false} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, subfloor: newValue})); }} />
            <Dropdown label="Er der målt planhed på gulvet?" options={yesNoOptions} defaultSelectedKey={"na"} onChange={(ev, option) => { updateModel(prevState => ({...prevState, isFloorLevelnessMeasured: option?.key + ""})) }} />
            <Dropdown label="Er der gulvvarme?" options={yesNoOptions} defaultSelectedKey={"na"} onChange={(ev, option) => { updateModel(prevState => ({...prevState, floorHeating: option?.key + ""})) }} />
            <TextField label="Beskriv reklamation" required={true} multiline={true} rows={8} onChange={(ev, newValue) => { updateModel(prevState => ({...prevState, description: newValue})); }} />
            <Label>Vedhæft filer (billeddokumentation, faktura mv.)</Label>
            <div className={styles.dropZone} {...getRootProps()}>
              <input {...getInputProps()} />
              <div>Træk filerne hertil eller klik for at gennemse</div>
            </div>
            {model.files != undefined && model.files.length > 0 &&
              <aside>
                <div><b>Valgte filer:</b></div>
                <ul>{files}</ul>
              </aside>
            }      
          </div>
      </div>
      <ReCAPTCHA
        sitekey="6LfuwcsiAAAAAIbUw2olCcRfRW0BwfgHEg0oEC9v"
        onChange={(val: string | null) => { setReCaptchaToken(val + ""); }}
      />
      <SpinnerButton ShowSpinner={isSubmitting}>
        <PrimaryButton disabled={!canSubmit || isSubmitting} className={styles.submitButton} text="Send indberetning" onClick={() => { submit() }} /> 
      </SpinnerButton>      
    </>
  );
}

interface IFormProps 
{
}

interface IFormModel
{
  orderNo?: string;
  description?: string;  
  m2?: string;

  /* Contact info */
  company?: string;
  contactName?: string;
  contactEmail?: string;
  contactMobilePhone?: string;

  quality?: string;
  design?: string;
  customerName?: string;
  customerAddress?: string;
  customerZipCode?: string;
  customerCity?: string;

  hasTheProductOrInstallationBeenPhysicallyInspected?: string;
  dateOfInspection?: Date;
  inspectedBy?: string;

  roomType?: string;
  subfloor?: string;
  isFloorLevelnessMeasured?: string;
  floorHeating?: string;

  files: IFile[];
}
export default Form;